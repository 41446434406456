/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { UIDReset, UIDConsumer } from 'react-uid';
import {
    object, string, arrayOf, shape, bool, func,
} from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import QueryString from 'qs';
import mbpLogger from 'mbp-logger';
import loadable from '@loadable/component';

// ui components
import BrandTabs from './BrandTabs';
import AboveTabs from './AboveTabs';
import SearchBlock from './SearchBlock';
import ConnectedCart from './Cart'; // importing Cart as ConnectedCart to address linter error
import ConnectedTrackOrders from './TrackOrders';
import ConnectedSignIn from './SignIn/SignIn';
import ContainerBrandLogo from './ContainerBrandLogo/ContainerBrandLogo';

// gql, redux, helpers
import extractMenuAttributes from '../../../helpers/contentstack/extractMenuAttributes';
import replaceLinkTokens from '../../../helpers/contentstack/replaceLinkTokens';
import isProductListingAd from '../../../helpers/isProductListingAd';
import { getUserRole } from '../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getFeatureFlags, getFeatureFlag, getPresentationFamily } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { selectors } from '../../../../state/ducks/App';
import customBreakpoints from '../../../helpers/customBreakpoints';
import { actions as tagManagerActions } from '../../../../state/ducks/TagManager';

const LoadableTopMenu = loadable(() => import(/* webpackChunkName: "TopMenu" */ './TopMenu/TopMenu'));
const LoadableTopMenuV2 = loadable(() => import(/* webpackChunkName: "TopMenuV2" */ './NavigationMenu_V2/Navigation_V2'));

const styles = (theme) => ({
    LogoRowContainer1: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '67px',
        borderBottom: theme.palette.desktopHeaderMenu.border,
    },
    LogoRowContainer2: {
        display: 'inline-block',
        padding: '0 10px',
        width: '100%',
        [theme.breakpoints.up(customBreakpoints.tabLandscape)]: {
            width: '1400px',
            minWidth: '960px',
        },
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            justifyContent: 'space-between',
        },
    },
    LogoRowContainer3: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 0,
        height: '100%',
    },
    LogoRowContainer4: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 0 0 40px',
        height: '100%',
        width: '100%',
        maxWidth: '300px',
        '& a': {
            textDecoration: 'none',
            textAlign: 'center',
            fontSize: '12px',
            color: '#000',
            '@media screen and (max-width: 850px)': {
                '& div, span': {
                    lineHeight: '1em',
                    width: '99%',
                    margin: '0 auto',
                },
            },
        },
        '& img': {
            width: 'auto',
            maxHeight: '25px',
            marginBottom: '2px',
        },
    },
    HeaderLogo: {
        maxHeight: '52px',
        width: 'auto',
        maxWidth: '21vw',
        paddingTop: '4px',
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            height: '4vw',
        },
    },
    LinkIcon: {
        marginTop: '2px',
        display: 'block',
        textDecoration: 'none',
        color: '#000',
        textAlign: 'center',
        fontSize: '11px',
        paddingRight: '5px',
    },
    plaBanner: {
        width: '100%',
        maxWidth: '510px',
    },
    secondaryNavigation: {
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            justifyContent: 'space-around',
            height: '30px',
        },
    },
});

const LINKSTYLE_NORMAL = 'Normal';
// const LINKSTYLE_DIVIDER = 'Divider';

const isMenuMissingOrEmpty = (blockObj, menuName) => {
    if (!blockObj.menu_ref || !Array.isArray(blockObj.menu_ref) || blockObj.menu_ref.length === 0
            || !blockObj.menu_ref[0].menu_blocks || !Array.isArray(blockObj.menu_ref[0].menu_blocks) || blockObj.menu_ref[0].menu_blocks.length === 0) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            message: `Missing or empty ${menuName} menu in DesktopHeaderBlock`,
            block: blockObj,
        });
        return true;
    }

    return false;
};

const isMenuMissingOrEmpty2 = (blockObj, menuName) => {
    if (isMenuMissingOrEmpty(blockObj, menuName)
            || !blockObj.menu_ref[0].menu_blocks[0].menu_links
            || !blockObj.menu_ref[0].menu_blocks[0].menu_links.menu_link
            || !Array.isArray(blockObj.menu_ref[0].menu_blocks[0].menu_links.menu_link)
            || blockObj.menu_ref[0].menu_blocks[0].menu_links.menu_link.length === 0) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            message: `block not in expected format in ${menuName} caller`,
            block: blockObj,
        });
        return true;
    }

    return false;
};

const isBrandTabsMissingOrEmpty = (brandTabs, menuName) => {
    if (
        !brandTabs
        || !Array.isArray(brandTabs)
        || brandTabs.length === 0) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            message: `brandTabs not in expected format in ${menuName} caller`,
            block: brandTabs,
        });
        return true;
    }

    return false;
};

const callAboveTabs = (blockObj) => {
    if (isMenuMissingOrEmpty2(blockObj, 'Above Tabs')) {
        return null;
    }

    const menu = blockObj.menu_ref[0];
    // This is a mini version of MenuBuilder
    const navMenu = [];
    menu.menu_blocks[0].menu_links.menu_link.forEach((item) => {
        const navItem = {
            label: item.link && item.link.title ? item.link.title : '', // eslint-disable-line key-spacing
            link:  item.link && item.link.href  ? item.link.href : '', // eslint-disable-line key-spacing
        };
        item.icon             && (navItem.icon            = item.icon);             // eslint-disable-line no-unused-expressions
        item.font_color       && (navItem.color           = item.font_color);       // eslint-disable-line no-unused-expressions
        item.background_color && (navItem.backgroundColor = item.background_color); // eslint-disable-line no-unused-expressions
        item.placement        && (navItem.placement = item.placement); // eslint-disable-line no-unused-expressions
        navMenu.push(navItem);
    });
    return <AboveTabs menu={navMenu} attributes={extractMenuAttributes(menu)} />;
};

const callBrandTabs = (brand, pathname, brandTabs, ffIsAttributionBrandTabsSupportEnabled) => {
    if (isBrandTabsMissingOrEmpty(brandTabs, 'Brand Tabs')) {
        return null;
    }

    // No brand tabs on T-Mobile pages
    const pageUrl = ['/tmobile-tuesdays', '/verizon-up-400203990', '/flowers-gifts-400209995', '/flowers-gifts-vanguard-080321'];
    if (pageUrl.indexOf(pathname) !== -1) {
        return false;
    }

    // This is a mini version of MenuBuilder
    const navMenu = [];
    brandTabs.forEach((item) => {
        if (item) {
            const linkStyle = item.link_style || LINKSTYLE_NORMAL;
            switch (linkStyle) {
                case LINKSTYLE_NORMAL:
                    navMenu.push({
                        label: item.url?.title ? item.url.title : '',   // brand code (e.g. HD)
                        link: item.url?.href ? replaceLinkTokens(item.url.href, brand) : '',
                        icon: item.icon || '',      // brand logo
                        fullBrandName: item.full_brand_name || '',
                        catalogId: item.catalog_id || '',
                        storeId: item.store_id || '',
                        domain: item.domain_name || '',
                        linkStyle: LINKSTYLE_NORMAL,
                    });
                    break;
                default:
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        message: 'Brand Tabs menu should not contain "`linkStyle`" items',
                        item,
                        block: brandTabs,
                    });
            }
        }
    });

    return (
        <BrandTabs
            menu={navMenu}
            currentBrand={brand}
            ffIsAttributionBrandTabsSupportEnabled={ffIsAttributionBrandTabsSupportEnabled}
        />
    );
};

// Logo (main) row methods

// The "main" row of the header (aka the Logo row) is a bit of a strange case.
// We can't quite follow the scheme of "just render everything the same in the order it appears in the block"
// because the scheme is:  Logo     search-box      everything-else-together-in-one-group
// so that's the scheme the code follows.
const renderLogoRow = (blockObj, brand, classes, location, featureFlags, userRole, presentation_family, trackEvent, country, flowerBrand, keeperData, globalPartnerData) => {
    if (isMenuMissingOrEmpty(blockObj, 'Logo Search Widgets')) {
        return null;
    }
    const isPlaEnabled = featureFlags['is-pla-flow-enabled'];
    const urlParams = QueryString.parse(location.search);
    const bannerCode = urlParams?.r || urlParams?.['?r'] || null;
    const plaURL = bannerCode?.includes('pla');
    const plaEnabled = isPlaEnabled && isProductListingAd(location?.search) && plaURL;
    /**
     * determineSeoBanner - determines banner to show based on bannercode
     * @param {string} bCode banner code
     * @returns {string} img url
     */
    const determineSeoBanner = (bCode) => {
        if (bCode?.includes('google')) {
            return '//images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt5fdc02cf0e2820a5/5e9b683bb170456000419312/pla_banner_desktop.jpg';
        }
        if (bCode?.includes('bing')) {
            return '//images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt9c9ba91ea5f8a85c/5f4f9176891266279cd6e244/pla_banner_desktop_bing.jpg';
        }
        return null;
    };

    return (
        <div className={classes.LogoRowContainer1}>
            <div className={classes.LogoRowContainer2}>
                <div className={classes.LogoRowContainer3}>
                    <ContainerBrandLogo
                        keeperData={keeperData}
                        menuBlocks={blockObj?.menu_ref?.[0]?.menu_blocks}
                        plaEnabled={plaEnabled}
                        partnershipLogo={globalPartnerData?.images?.shared?.url}
                    />
                    {plaEnabled ? (
                        [
                            (determineSeoBanner(bannerCode)
                                ? (
                                    <img
                                        className={classes.plaBanner}
                                        src={determineSeoBanner(bannerCode)}
                                        alt="Welcome Customers"
                                        aria-hidden
                                    />
                                )
                                : null
                            ),
                        ]
                    ) : [
                        null,
                    ]}

                    <UIDReset>
                        <UIDConsumer>
                            {(id, uid) => blockObj?.menu_ref?.[0]?.menu_blocks.map(
                                (block) => <LogoRowBlock block={block} plaEnabled={plaEnabled} brand={brand} key={uid(block)} classes={classes} featureFlags={featureFlags} userRole={userRole} trackEvent={trackEvent} country={country} isFlowerBrand={flowerBrand} />,
                            )}
                        </UIDConsumer>
                    </UIDReset>
                </div>
            </div>
        </div>
    );
};

const LogoRowBlock = ({
    block, brand, classes, featureFlags, userRole, plaEnabled, trackEvent, country, isFlowerBrand,
}) => {
    const menuBlockKey = Object.keys(block)[0];
    const menuBlockObj = block[menuBlockKey];

    switch (menuBlockKey) {
        case 'logo':
            break;  // already rendered this

        case 'widgets':
            switch (menuBlockObj.type) {
                case '[SEARCH]':
                    return !plaEnabled && (!isFlowerBrand || (isFlowerBrand && (country === 'united states' || country === '')))
                        ? <SearchBlock brand={brand} typeSearchBox="desktop" />
                        : null;

                case '[SHOPPING_CART]':
                    break;
                    // return <Cart />;

                default:
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        message: `Unknown menu-block widget type ${menuBlockObj.type} in DesktopHeaderBlock`,
                        brand,
                        block,
                    });
                    return null;
            }
            break;

        case 'menu_links':
            if (menuBlockObj.menu_link && Array.isArray(menuBlockObj.menu_link) && menuBlockObj.menu_link.length) {
                return (
                    <div className={classes.LogoRowContainer4} data-testid="header-logo">
                        <UIDReset>
                            <UIDConsumer>
                                {(id, uid) => menuBlockObj.menu_link.map(
                                    (menuLinkObj) => <LogoRowBlockLink obj={menuLinkObj} brand={brand} key={uid(menuLinkObj)} classes={classes} featureFlags={featureFlags} userRole={userRole} trackEvent={trackEvent} isFlowerBrand={isFlowerBrand} menuLink={menuBlockObj?.menu_link} />,
                                )}
                            </UIDConsumer>
                        </UIDReset>
                    </div>
                );
            }
            return null;

        default:
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                message: `Unknown menu-block type ${menuBlockKey} in DesktopHeaderBlock`,
                brand,
                block: menuBlockObj,
            });
            return null;
    }

    return null;
};

LogoRowBlock.propTypes = {
    classes: object.isRequired,
    block: object.isRequired,
    brand: object.isRequired,
    featureFlags: object.isRequired,
    userRole: string.isRequired,
    plaEnabled: bool.isRequired,
    trackEvent: func.isRequired,
    country: string.isRequired,
    isFlowerBrand: bool.isRequired,
};

const LogoRowBlockLink = ({
    obj, brand, featureFlags, userRole, trackEvent,
}) => {
    const title = obj && obj.link && obj.link.title ? obj.link.title : null;
    const href = obj && obj.link && obj.link.href ? obj.link.href : '/';
    const isSigninEnabled = featureFlags['is-signin-enabled'];
    const icon = (userRole === 'P' && obj.passport_user_icon) ? obj.passport_user_icon : obj.icon;

    switch (title) {
        case 'Sign In':
            return (isSigninEnabled) ? <ConnectedSignIn image={icon} trackEvent={trackEvent} /> : null;

        case 'My Orders':
            return (isSigninEnabled) ? <ConnectedTrackOrders languageId={1} brand={brand} image={obj.icon || ''} /> : null;

        case 'Shopping Cart':
            return <ConnectedCart image={obj.icon || ''} />;

        case 'Gift List':
            return (
                <Link to={href}>
                    <img src={obj.icon || ''} alt="Red Gift Box" aria-hidden="true" role="presentation" height="25" width="25" />
                    <div>Gift List</div>
                </Link>
            );

        default:
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                message: `Unknown menu-block-link ${title} or unexpected structure in DesktopHeaderBlock`,
                brand,
                obj,
            });
            return null;
    }
};

LogoRowBlockLink.propTypes = {
    obj: object.isRequired,
    brand: object.isRequired,
    featureFlags: object.isRequired,
    userRole: string.isRequired,
    trackEvent: func.isRequired,
};

// Each block returned from ContentStack is a row of the desktop header.
// This component is called by DesktopGraphqlHeader to render one block (one row) of the desktop header.
const DesktopHeaderBlock = ({
    keeperData, block, brand, classes, location, featureFlags, brandTabs, countryName, presentation_family, userRole, trackEvent, isMainNavV2Available, ffIsAttributionBrandTabsSupportEnabled, globalPartnerData,
}) => {
    const blockKey = Object.keys(block)[0];
    const blockObj = block[blockKey];
    const isPlaEnabled = featureFlags['is-pla-flow-enabled'];
    const { pathname } = location;
    const country = countryName.toLowerCase();
    const internationalFlower = location?.pathname === '/international-flower-delivery';
    const flowerBrand = presentation_family === 'flower';
    const disableBlockCountry = country && country !== 'united kingdom' && country !== 'united states' && country !== 'canada' && country !== 'england';
    const isNonClickableCollection = location?.pathname === '/flowers-gifts-vanguard-080321';
    // TODO:
    // This is the beginning of default logic to determine the type of blockObj.
    // Currently, the onus is placed on the content creator to determine the
    // content type by entering a specific string in what is typically an arbitrary field.
    // This is obviously an incredibly poor pattern and has led to a lot of frustration and broken
    // header navigations because this explicit requirement is NEVER messaged to the content creator.
    let blockName = blockObj.name;

    if (blockObj?.menu_ref?.[0]?._content_type_uid === 'main_navigation_v2') {
        blockName = 'main_navigation_v2';
        // blockName.referenceData = { // this component requires this data to fetch
        //     contentType: blockName,
        //     uid: blockObj.menu_ref[0].uid,
        // };
    }

    if (!blockName) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            message: 'Unknown header block type (or missing menu name) in DesktopHeaderBlock',
            brand,
            block: blockObj,
        });
        return null;
    }

    const isTopNavV2Test = featureFlags['is-top-navigation-v2-enabled'];
    if (isTopNavV2Test && isMainNavV2Available && blockName === 'Top Nav') return <></>;
    if (!isTopNavV2Test && blockName === 'main_navigation_v2') return <></>;

    switch (blockName) { // Main Navigation V2 now there
        case 'Above Tabs':
            if (isPlaEnabled && isProductListingAd(location.search)) {
                return null;
            }
            return callAboveTabs(blockObj);
        case 'Brand Tabs':
            if ((isPlaEnabled && isProductListingAd(location.search)) || Object.keys(globalPartnerData).length) {
                return null;
            }
            if ((disableBlockCountry || internationalFlower) && flowerBrand) {
                return null;
            }
            return callBrandTabs(brand, pathname, brandTabs, ffIsAttributionBrandTabsSupportEnabled);

        case 'Logo Search Widgets':
            return renderLogoRow(blockObj, brand, classes, location, featureFlags, userRole, presentation_family, trackEvent, country, flowerBrand, keeperData, globalPartnerData);

        case 'Top Nav':
            if (isMenuMissingOrEmpty(blockObj, 'TopNav')) {
                return null;
            }

            if ((disableBlockCountry || internationalFlower || isNonClickableCollection) && flowerBrand) {
                return null;
            }
            return (
                <LoadableTopMenu
                    menu={blockObj.menu_ref[0].menu_blocks}
                    brand={brand}
                    attributes={blockObj.menu_ref[0]}
                    ariaLabel="Main Navigation"
                />
            );

        case 'Top Nav 2':
            if (isMenuMissingOrEmpty(blockObj, 'TopNav')) {
                return null;
            }
            return (
                <LoadableTopMenu
                    menu={blockObj.menu_ref[0].menu_blocks}
                    brand={brand}
                    attributes={blockObj.menu_ref[0]}
                    ariaLabel="Secondary Navigation"
                    className={classes.secondaryNavigation}
                />
            );

        case 'main_navigation_v2':
            return (
                <LoadableTopMenuV2
                    entryData={blockObj}
                    brand={brand}
                    ariaLabel="Main Navigation"
                    keeperData={keeperData}
                />
            );

        default:
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                message: `Unknown header block type "${blockObj.name}" in DesktopHeaderBlock`,
                brand,
                block: blockObj,
            });
            return null;
    }
};

DesktopHeaderBlock.propTypes = {
    block: object.isRequired,
    classes: object.isRequired,
    brand: object.isRequired,
    location: object.isRequired,
    featureFlags: object.isRequired,
    userRole: string.isRequired,
    brandTabs: arrayOf(shape({
        brand_id: string.isRequired,
        catalog_id: string.isRequired,
        domain_name: string.isRequired,
        full_brand_name: string.isRequired,
        icon: string.isRequired,
        store_id: string.isRequired,
        url: shape({
            href: string.isRequired,
            title: string.isRequired,
        }).isRequired,
    })).isRequired,
    countryName: string,
    presentation_family: string.isRequired,
    trackEvent: func.isRequired,
    isMainNavV2Available: bool.isRequired,
    ffIsAttributionBrandTabsSupportEnabled: bool.isRequired,
    keeperData: object,
    globalPartnerData: object,
};

DesktopHeaderBlock.defaultProps = {
    countryName: '',
    keeperData: {},
    globalPartnerData: {},
};

const mapStateToProps = (state) => ({
    countryName: selectors.country(state),
    presentation_family: getPresentationFamily(state),
    userRole: getUserRole(state),
    featureFlags: getFeatureFlags(state),
    ffIsAttributionBrandTabsSupportEnabled: getFeatureFlag('is-attribution-brand-tabs-full-support-enabled')(state),
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(tagManagerActions.trackEvent, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(DesktopHeaderBlock)));
